import React from "react";
import NavBar from "../components/NavBar";
import Footer from "../components/Footer";
import i1 from "../assets/teams/GNV.png";
import i2 from "../assets/teams/GRV.png";
import i3 from "../assets/teams/GVV.png";
import i4 from "../assets/teams/Raksha.png";
import i5 from "../assets/teams/Ganganna.png";
import i6 from "../assets/teams/Pallavi.png";
import i7 from "../assets/teams/Harsha.png";

const TeamPage = () => {
  return (
    <div>
      <NavBar />

      <div className="w-full mt-10 md:mt-20  sm:flex justify-center items-center text-left">
        <div className="w-full md:p-20 p-6 bg-[#CCAC00] rounded-r-3xl bg-opacity-20 md:mr-20">
          <h1 className="font-semibold text-xl sm:text-2xl md:text-3xl  animate-slide-up text-[#5B5643]">
            Our Leadership
          </h1>
          <h1 className="mt-6  text-xs sm:text-sm md:text-lg font-light animate-fade-in delay-900 text-[#5B5643]">
            Our leadership team brings extensive real estate expertise, driving
            innovation, integrity, and client satisfaction. With a focus on
            strategic growth and market trends, they ensure we deliver
            exceptional value and high-quality properties.
          </h1>
        </div>
      </div>

      <div className="p-6 md:px-20 flex flex-col sm:flex-row items-center sm:items-stretch justify-center ">
        <div className="sm:w-1/3 md:w-2/5 lg:w-1/5 flex justify-center items-center ">
          <img alt="owner" src={i1}></img>
        </div>

        <div className="flex flex-col sm:w-2/3 md:w-3/5 lg:w-4/5 md:justify-start text-[#5B5643] p-4 mt-2 sm:ml-4 sm:px-6 md:px-6 border-2 shadow rounded-lg">
          <h1 className="text-xl sm:text-2xl md:text-3xl font-semibold w-full text-center sm:text-start">
            G N VENUGOPAL
          </h1>
          <h1 className="text-sm sm:text-lg md:text-xl text-center sm:text-start">
          Managing Partner(Acquisition)
          </h1>
          <h1 className="text-sm sm:text-lg md:text-xl text-center sm:text-start">
          Experience: 35+ Years
          </h1>
          <h1 className="mt-4 text-xs  sm:text-sm md:text-lg font-light animate-fade-in delay-900 text-[#5B5643] w-full ">
          Having the 35+ years of experience in acquisition of land in real-estate plays a prominent role just like a main pillar in land acquisition for our projects, have joined the company to give the best prime support to the company to give the best to there customers.
          </h1>
        </div>
      </div>

      <div className="flex flex-col xl:flex-row md:px-20">

        <div className="p-6  flex-col sm:flex-row flex items-center sm:items-stretch justify-center xl:w-1/2">
          <div className="sm:w-1/3 md:w-1/5 xl:w-2/5 flex justify-center items-center">
            <img alt="owner" src={i2}></img>
          </div>

          <div className="flex flex-col sm:w-2/3 md:w-4/5 xl:w-3/5 justify-start text-[#5B5643] sm:ml-4 sm:px-6 md:px-5 mt-2 p-4 border-2 shadow rounded-lg">
            <h1 className="text-xl sm:text-2xl md:text-3xl font-semibold text-center sm:text-start">G REKHA VENUGOPAL</h1>
            <h1 className="text-sm sm:text-lg md:text-xl text-center sm:text-start">Managing Partner(Financer)</h1>
            <h1 className="text-sm sm:text-lg md:text-xl text-center sm:text-start">
          Experience: 15+ Years
          </h1>
            <h1 className="mt-4 text-xs sm:text-sm md:text-lg font-light animate-fade-in delay-900 text-[#5B5643] w-full ">
            As an graduate in LLB & B.Com and having the finance experience for 12+ years, have joined the company to guide and give the best guidelines for the RAK Groups.
            </h1>
          </div>
        </div>

        <div className="p-6  flex-col sm:flex-row flex items-center sm:items-stretch justify-center  xl:w-1/2">
          <div className="sm:w-1/3 md:w-1/5 xl:w-2/5 flex justify-center items-center">
            <img alt="owner" src={i3}></img>
          </div>

          <div className="flex flex-col sm:w-2/3 md:w-4/5 xl:w-3/5 justify-start text-[#5B5643] sm:px-6 sm:ml-4 md:px-5 mt-2 p-4 border-2 shadow rounded-lg">
            <h1 className="text-xl sm:text-2xl md:text-3xl font-semibold text-center sm:text-start">G V VIVEK</h1>
            <h1 className="text-sm sm:text-lg md:text-xl text-center sm:text-start">Managing Director(Founder)</h1>
            <h1 className="text-sm sm:text-lg md:text-xl text-center sm:text-start">
          Experience: 5+ Years
          </h1>
            <h1 className="mt-4 text-xs sm:text-sm md:text-lg font-light animate-fade-in delay-900 text-[#5B5643] w-full ">
            Mr.  Vivek G V is the founder and managing director of RAK Groups, which is one of the fast growing real-estate company, with 5+ years of experience in the industry. 
            </h1>
          </div>
        </div>

      </div>

      <div className="px-4 sm:px-12 md:px-20 lg:px-20 flex flex-col justify-center items-center mt-10 sm:mt-16 animate-fade-in w-full">
        <h1 className="text-xl sm:text-2xl md:text-3xl mb-2 w-full text-[#5B5643]">Legal and Infra Team</h1>
        <div className="w-full relative justify-center flex flex-col md:mb-10 mb-4">
          <hr className="bg-gray-400 h-0.5 w-full absolute" />
          <hr className="bg-yellow-500 h-1 w-1/4 absolute" />
        </div>

        <div className="mt-6 sm:mt-8 xl:mt-12 grid grid-cols-2 sm:grid-cols-4 lg:grid-cols-4 gap-4 md:gap-8 justify-between w-full  mb-14 animate-fade-in delay-300 text-[#5B5643]">
          <div className="animate-zoom-in delay-100  ">
            <img alt="owner" src={i4}></img>
            <h1 className="text-sm lg:text-xl">G V RAKSHA</h1>
            <h1 className="text-xs lg:text-lg">Legal Head</h1>
          </div>
          <div className="animate-zoom-in delay-200">
            <img alt="owner" src={i5}></img>
            <h1 className="text-sm lg:text-xl">GANGADHAR</h1>
            <h1 className="text-xs lg:text-lg">Legal Mentorship</h1>
          </div>
          <div className="animate-zoom-in delay-300">
            <img alt="owner" src={i6}></img>
            <h1 className="text-sm lg:text-xl">PALLAVI ASHOK NAIK</h1>
            <h1 className="text-xs lg:text-lg">Architecture</h1>
          </div>
          <div className="animate-zoom-in delay-400">
            <img alt="owner" src={i7}></img>
            <h1 className="text-sm lg:text-xl">HARSHA RAJ SHETTY</h1>
            <h1 className="text-xs lg:text-lg">Engineer</h1>
          </div>
        </div>
      </div>

      <div className="w-full bg-[#5B5643] md:flex text-white justify-center items-center md:py-4 lg:py-10 xl:py-20">
        <div className="md:w-1/2 lg:w-2/5 md:p-20 px-6 py-2  md:py-0">
          <h1 className="text-xl sm:text-2xl md:text-3xl font-semibold">
            WORK WITH US
          </h1>
          <h1 className="text-sm sm:text-lg md:text-xl mt-3 md:mt-6">
            Have a question? Give a call.
          </h1>
          <h1 className="text-xl sm:text-2xl mt-3 md:text-3xl md:mt-6 flex gap-6">
            <svg
              width="34"
              height="34"
              viewBox="0 0 34 34"
              className="w-6 md:w-10"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                d="M27.4029 12.2016C27.4029 11.2416 26.6511 9.76956 25.5315 8.56956C24.5078 7.46556 23.1482 6.60156 21.8047 6.60156"
                stroke="white"
                stroke-linecap="round"
                stroke-linejoin="round"
              />
              <path
                d="M33.001 12.2C33.001 6.008 27.9947 1 21.8047 1"
                stroke="white"
                stroke-linecap="round"
                stroke-linejoin="round"
              />
              <path
                d="M32.9996 27.128C32.9996 27.704 32.8714 28.296 32.599 28.872C32.3266 29.448 31.9741 29.992 31.5094 30.504C30.7242 31.368 29.8589 31.992 28.8815 32.392C27.92 32.792 26.8785 33 25.7568 33C24.1224 33 22.3758 32.616 20.533 31.832C18.6903 31.048 16.8476 29.992 15.0208 28.664C13.1781 27.32 11.4315 25.832 9.76503 24.184C8.11458 22.52 6.62436 20.776 5.29438 18.952C3.98043 17.128 2.92286 15.304 2.15372 13.496C1.38457 11.672 1 9.928 1 8.264C1 7.176 1.19229 6.136 1.57686 5.176C1.96143 4.2 2.57033 3.304 3.4196 2.504C4.44512 1.496 5.56679 1 6.75255 1C7.20122 1 7.64989 1.096 8.05048 1.288C8.4671 1.48 8.83565 1.768 9.12408 2.184L12.8416 7.416C13.13 7.816 13.3383 8.184 13.4826 8.536C13.6268 8.872 13.7069 9.208 13.7069 9.512C13.7069 9.896 13.5947 10.28 13.3704 10.648C13.1621 11.016 12.8576 11.4 12.4731 11.784L11.2552 13.048C11.079 13.224 10.9989 13.432 10.9989 13.688C10.9989 13.816 11.0149 13.928 11.0469 14.056C11.095 14.184 11.1431 14.28 11.1751 14.376C11.4636 14.904 11.9603 15.592 12.6653 16.424C13.3864 17.256 14.1556 18.104 14.9888 18.952C15.8541 19.8 16.6873 20.584 17.5366 21.304C18.3698 22.008 19.0589 22.488 19.6037 22.776C19.6838 22.808 19.7799 22.856 19.8921 22.904C20.0203 22.952 20.1485 22.968 20.2927 22.968C20.5651 22.968 20.7734 22.872 20.9497 22.696L22.1675 21.496C22.5681 21.096 22.9526 20.792 23.3212 20.6C23.6897 20.376 24.0583 20.264 24.4589 20.264C24.7633 20.264 25.0838 20.328 25.4363 20.472C25.7889 20.616 26.1574 20.824 26.558 21.096L31.8619 24.856C32.2785 25.144 32.5669 25.48 32.7432 25.88C32.9034 26.28 32.9996 26.68 32.9996 27.128Z"
                stroke="white"
                stroke-miterlimit="10"
              />
            </svg>
            9059005900
          </h1>
        </div>
        <div className="md:w-1/2 lg:w-3/5 md:pr-20 px-6 py-2 md:p-6 md:py-0">
          <h1 className="md:mt-6 md:leading-10 text-xs sm:text-sm md:text-lg font-light animate-fade-in delay-900 ">
            Our goal is to offer an unparalleled level of service to our highly
            respected clients. Whether you are looking to buy home, we guarantee
            that our expertise, professionalism and dedication will guide you
            toward meeting your unique real estate needs.
          </h1>
        </div>
      </div>

      <Footer />
    </div>
  );
};

export default TeamPage;
