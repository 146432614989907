import React, { useState, useEffect } from "react";
import NavBar from "../components/NavBar";
import Footer from "../components/Footer";
import f1 from "../assets/PlotVillaDetails/features/f1.svg";
import f2 from "../assets/PlotVillaDetails/features/f2.svg";
import f3 from "../assets/PlotVillaDetails/features/f3.svg";
import f4 from "../assets/PlotVillaDetails/features/f4.svg";
import n1 from "../assets/PlotVillaDetails/villaamap.svg";
import a1 from "../assets/amenities/1.svg";
import a2 from "../assets/amenities/2.svg";
import a3 from "../assets/amenities/3.svg";
import a4 from "../assets/amenities/4.svg";
import a5 from "../assets/amenities/5.svg";
import a6 from "../assets/amenities/6.svg";
import a7 from "../assets/amenities/7.svg";
import a8 from "../assets/amenities/8.svg";
import a9 from "../assets/amenities/9.svg";
import c1 from "../assets/PlotVillaDetails/features/c_villa/1.png";
import c2 from "../assets/PlotVillaDetails/features/c_villa/2.png";
import c3 from "../assets/PlotVillaDetails/features/c_villa/3.png";
import c4 from "../assets/PlotVillaDetails/features/c_villa/4.png";
import c5 from "../assets/PlotVillaDetails/features/c_villa/5.png";
import c6 from "../assets/PlotVillaDetails/features/c_villa/6.png";
import { Carousel } from "react-responsive-carousel";
import carousel1 from "../assets/Carousel1.png";
import image1 from "../assets/PlotVillaDetails/villaFP.png";
import image2 from "../assets/plotdetailspage1.png";
import { Link } from "react-router-dom";
import { useNavigate } from "react-router-dom";
import { useAuth } from "../context/AuthContext";

const VillaDetailsPage = () => {
  const [selectedPlan, setSelectedPlan] = useState("floor");
  const { isAuthenticated } = useAuth(); // Check if user is authenticated
  const navigate = useNavigate();
  const [activeIndex, setActiveIndex] = useState(0);
  const [isMobile, setIsMobile] = useState(false);
  const [selectedDimension, setSelectedDimension] = useState("1020 sqft");
  const [price, setPrice] = useState("Rs 50000/-");

  const villaOptions = [
    { dimension: "1020 sqft", price: "Rs 50000/-" },
    { dimension: "1200 sqft", price: "Rs 60000/-" },
    { dimension: "1360 sqft", price: "Rs 70000/-" },
    { dimension: "2200 sqft", price: "Rs 80000/-" },
    { dimension: "2240 sqft", price: "Rs 60000/-" },
    { dimension: "2320 sqft", price: "Rs 70000/-" },
    { dimension: "2210 sqft", price: "Rs 80000/-" },
  ];

  // Handle dropdown change
  const handleDimensionChange = (e) => {
    const selected = e.target.value;
    setSelectedDimension(selected);

    // Update price based on selected dimension
    const selectedOption = villaOptions.find(
      (option) => option.dimension === selected
    );
    if (selectedOption) {
      setPrice(selectedOption.price);
    }
  };

  const projects = [
    {
      id: 1,
      title: "Project 1",
      imageUrl: c1,
     
    },
    {
      id: 2,
      title: "Project 2",
      imageUrl: c2,
    
    },
    {
      id: 3,
      title: "Project 3",
      imageUrl: c3,
    
    },
    {
      id: 3,
      title: "Project 4",
      imageUrl: c4,
     
    },
    {
      id: 3,
      title: "Project 5",
      imageUrl: c5,
      
    },
    {
      id: 3,
      title: "Project 6",
      imageUrl: c6,
     
    },
  ];

  const handleSlideChange = (index) => {
    setActiveIndex(index);
  };

  useEffect(() => {
    const updateMobileMode = () => {
      setIsMobile(window.innerWidth < 768); // Set mobile for screens less than 768px
    };
    window.addEventListener("resize", updateMobileMode);
    updateMobileMode(); // Set initial state based on screen width
    return () => window.removeEventListener("resize", updateMobileMode);
  }, []);

  const handleUnlockPriceClick = () => {
    if (isAuthenticated) {
      // Show price if user is logged in
      return;
    } else {
      // If user is not logged in, redirect to login page
      navigate("/login", { state: { from: window.location.pathname } });
    }
  };

  return (
    <div className="bg-white text-[#5B5643]">
      <NavBar />
      <div className="w-full mt:10 md:mt-20  sm:flex justify-center items-center text-left text-gray-500 h-fit">
        <div className="w-full md:p-20 p-6 bg-[#CCAC00] rounded-r-3xl bg-opacity-20 md:mr-20">
          <h1 className="font-semibold text-xl sm:text-2xl md:text-3xl  animate-slide-up text-[#5B5643]">
            RAK Felicity II
          </h1>

          <h1 className="mt-6 text-xs sm:text-sm md:text-lg font-light animate-fade-in delay-900 text-[#5B5643]">
            RAK Felicity is a beautiful Villa plot community in Devanahalli
            surrounded by Nature with delightful amenities. The location is just
            a short drive from the city & main highway, but far enough to enjoy
            the peace and privacy. RAK Felicity is located just 10 mins from
            Bengaluru International Airport which is a prime location. This is
            your chance to live a luxurious life. RAK Felicity by Vencer offers
            you RERA approved Villa plots with amenities like Kid’s Play area,
            Jogging track, Yoga deck, Senior Citizen heaven, Outdoor Gym, Open
            lawn area, Swing park, 24/7 CCTV, Underground recharging & Cycling
            track. track.
          </h1>
        </div>
      </div>

      <div className="w-full p-6 md:px-20 flex flex-col md:flex-row md:space-x-8 space-y-6 md:space-y-0 justify-center items-center">
        <div className="flex border rounded-xl shadow gap-6 p-6 w-full">
          <img alt="plots" src={f1}></img>
          <div className="flex flex-col">
            <h1 className="text-[#CCAC00] font-semibold text-xl sm:text-2xl md:text-3xl">
             17
            </h1>
            <h1 className="text-sm sm:text-lg md:text-xl">Villa Plots</h1>
          </div>
        </div>

        <div className="flex border rounded-xl shadow gap-6 p-6 w-full">
          <img alt="plots" src={f2}></img>
          <div className="flex flex-col">
            <h1 className="text-[#CCAC00] font-semibold text-xl sm:text-2xl md:text-3xl">
              5999*/sqft
            </h1>
            <h1 className="text-sm sm:text-lg md:text-xl">Starting Price</h1>
          </div>
        </div>

        <div className="flex border rounded-xl shadow gap-6 p-6 w-full">
          <img alt="plots" src={f3}></img>
          <div className="flex flex-col">
            <h1 className="text-[#CCAC00] font-semibold text-xl sm:text-2xl md:text-3xl">
              4+
            </h1>
            <h1 className="text-sm sm:text-lg md:text-xl">Acres Layout</h1>
          </div>
        </div>

        <div className="flex border rounded-xl shadow gap-6 p-6 w-full">
          <img alt="plots" src={f4}></img>
          <div className="flex flex-col">
            <h1 className="text-[#CCAC00] font-semibold text-xl sm:text-2xl md:text-3xl">
              30+
            </h1>
            <h1 className="text-sm sm:text-lg md:text-xl">
              Luxurious Amenities
            </h1>
          </div>
        </div>
      </div>

      {/* Floor Plans Section */}
      <section className="p-6 md:px-20 text-center">
        {/* Button Section */}
        <div className="flex justify-center mt-8 space-x-2 sm:space-x-10 font-semibold text-xl sm:text-2xl  border-b">
          <button
            onClick={() => setSelectedPlan("floor")}
            className={`sm:w-56 py-2 px-6  ${
              selectedPlan === "floor"
                ? "border-b-2 border-[#CCAC00] font-semibold"
                : " hover:bg-[#CCAC00] hover:text-black"
            }`}
          >
            Floor Plans
          </button>
          <button
            onClick={() => setSelectedPlan("master")}
            className={`sm:w-56 py-2 px-6  ${
              selectedPlan === "master"
                ? "border-b-2 border-[#CCAC00] font-semibold"
                : " hover:bg-[#CCAC00] hover:text-black"
            }`}
          >
            Master Plans
          </button>
        </div>
        <hr className="w-1 bg-black" />
        {/* Conditional Rendering Based on Selected Plan */}
        {selectedPlan === "floor" ? (
          <div>
            <div className="flex flex-col md:flex-row items-center justify-center  mt-10 md:space-x-4">
              <img
                src={image1}
                alt="Floor Plan"
                className="w-full border-2 shadow rounded-xl"
              />

              <div className="md:w-2/5 md:h-full p-4 border-2 shadow rounded-xl mt-4 flex flex-col justify-center items-start">
                <h1 className="font-semibold text-xl sm:text-2xl md:text-3xl">
                  Floor Plan
                </h1>
                <h1 className="opacity-80 text-sm sm:text-lg md:text-xl mt-4 md:mt-6">
                  Configuration Type
                </h1>
                <h1 className=" text-sm sm:text-lg md:text-xl mt-2 md:mt-3">
                  Empty Plots
                </h1>
                <hr className="w-full mt-2 md:mt-3" />
                <h1 className="opacity-80 text-sm sm:text-lg md:text-xl mt-2 md:mt-3">
                  Villa Plot Area
                </h1>
                <select
                  value={selectedDimension}
                  onChange={handleDimensionChange}
                  className=" bg-transparent p-2 mt-2 md:mt-3"
                >
                  {villaOptions.map((option) => (
                    <option key={option.dimension} value={option.dimension}>
                      {option.dimension}
                    </option>
                  ))}
                </select>
                <hr className="w-full mt-2 md:mt-3" />
                <div className="mt-4 flex flex-col md:flex-row justify-center items-center">
                  <button
                    className="bg-[#CCAC00] text-black py-2 px-6 rounded hover:bg-yellow-400 w-full"
                    onClick={handleUnlockPriceClick}
                  >
                    {isAuthenticated ? price : "Unlock Price"}
                  </button>
                </div>
              </div>
            </div>
          </div>
        ) : (
          <div>
            <div className="flex flex-col md:flex-row items-center justify-center mt-10 md:space-x-4">
              <img
                src={image2} // Replace with actual image source for Master Plan
                alt="Master Plan"
                className="w-full border-2 sadow rounded-xl"
              />

              <div className="md:w-2/5 md:h-full p-4 border-2 shadow rounded-xl mt-4 flex flex-col justify-center items-start">
                <h1 className="font-semibold text-xl sm:text-2xl md:text-3xl">
                  Floor Plan
                </h1>
                <h1 className="opacity-80 text-sm sm:text-lg md:text-xl mt-4 md:mt-6">
                  Configuration Type
                </h1>
                <h1 className=" text-sm sm:text-lg md:text-xl mt-2 md:mt-3">
                  Empty Plots
                </h1>
                <hr className="w-full mt-2 md:mt-3" />
                <h1 className="opacity-80 text-sm sm:text-lg md:text-xl mt-2 md:mt-3">
                  Villa Plot Area
                </h1>
                <select
                  value={selectedDimension}
                  onChange={handleDimensionChange}
                  className=" bg-transparent p-2 mt-2 md:mt-3"
                >
                  {villaOptions.map((option) => (
                    <option key={option.dimension} value={option.dimension}>
                      {option.dimension}
                    </option>
                  ))}
                </select>
                <hr className="w-full mt-2 md:mt-3" />
                <div className="mt-4 flex flex-col md:flex-row justify-center items-center">
                  <button
                    className="bg-[#CCAC00] text-black py-2 px-6 rounded hover:bg-yellow-400 w-full"
                    onClick={handleUnlockPriceClick}
                  >
                    {isAuthenticated ? price : "Unlock Price"}
                  </button>
                </div>
              </div>
            </div>
          </div>
        )}
      </section>

      <div className="w-full bg-[#5B5643] p-6 md:px-20">
        <h1 className="font-semibold text-white text-xl sm:text-2xl md:text-3xl mb-2">
          Amenities
        </h1>
        <div className="w-full relative justify-center flex flex-col md:mb-10 mb-4">
          <hr className="bg-gray-400 h-0.5 w-full absolute" />
          <hr className="bg-[#CCAC00] h-1 w-1/4 absolute" />
        </div>
        <div className="grid md:grid-cols-5 text-white items-center md:justify-center space-y-4 md:space-y-0 text-xs sm:text-sm md:text-lg">
          <div className="flex space-x-6 md:p-10 items-center md:justify-start justify-between">
            <img alt="yoga" src={a1}></img>
            <h1>Yoga Dack</h1>
          </div>
          <div className="flex space-x-6 md:p-10 items-center md:justify-start justify-between">
            <img alt="jogging" src={a2}></img>
            <h1>Jogging Path</h1>
          </div>
          <div className="flex space-x-6 md:p-10 items-center md:justify-start justify-between">
            <img alt="play" src={a3}></img>
            <h1>Kids Play Area</h1>
          </div>
          <div className="flex space-x-6 md:p-10 items-center md:justify-start justify-between">
            <img alt="hall" src={a4}></img>
            <h1>Meditation Hall</h1>
          </div>
          <div className="flex space-x-6 md:p-10 items-center md:justify-start justify-between">
            <img alt="garden" src={a5}></img>
            <h1>Garden With Site-out</h1>
          </div>
          <div className="flex space-x-6 md:p-10 items-center md:justify-start justify-between">
            <img alt="Haven" src={a6}></img>
            <h1>Senior Citizen Haven</h1>
          </div>
          <div className="flex space-x-6 md:p-10 items-center md:justify-start justify-between">
            <img alt="Gym" src={a7}></img>
            <h1>Outdoor Gym</h1>
          </div>
          <div className="flex space-x-6 md:p-10 items-center md:justify-start justify-between">
            <img alt="cctv" src={a8}></img>
            <h1>24/7 CCTV</h1>
          </div>
          <div className="flex space-x-6 md:p-10 items-center md:justify-start justify-between">
            <img alt="cycling" src={a9}></img>
            <h1>Cycling Track</h1>
          </div>
          <div className="flex space-x-6 md:p-10 items-center md:justify-start justify-end">
            <h1>And many more!!!</h1>
          </div>
        </div>
      </div>

      <div className="w-full md:mt-20 p-6 md:px-20 text-[#5B5643]">
        <div className="flex justify-between items-center">
          <h1 className=" font-semibold text-xl sm:text-2xl md:text-3xl mb-2">
            Nearby Locations
          </h1>
        </div>
        <div className="w-full relative justify-center flex flex-col md:mb-10 mb-4">
          <hr className="bg-gray-400 h-0.5 w-full absolute" />
          <hr className="bg-[#CCAC00] h-1 w-1/4 absolute" />
        </div>
        <div className="grid md:grid-cols-3 gap-6 items-center md:justify-center space-y-4 md:space-y-0 ">
          <div className="flex flex-col md:space-x-6 col-span-2 md:col-span-1 p-2 md:p-10 items-center md:justify-start justify-between bg-[#F8F7F5] text-xs sm:text-sm md:text-lg">
            <div className="flex w-full text-sm sm:text-lg md:text-xl font-semibold py-2 items-center  justify-start">
              <h1>Hospitals</h1>
            </div>
            <div className="flex w-full py-2 items-center  justify-between">
              <h1>New Manasa Hospital</h1>
              <h1>2.1 km</h1>
            </div>
            <div className="flex w-full py-2 items-center  justify-between">
              <h1>Raja's Clinic</h1>
              <h1>2.9 km</h1>
            </div>
            <div className="flex w-full py-2 items-center  justify-between">
              <h1>Shri Shirdi Sai Hospital</h1>
              <h1>2.9 km</h1>
            </div>
            <div className="flex w-full py-2 items-center  justify-between">
              <h1>Ramaiah Leena Hospital</h1>
              <h1>3.1 km</h1>
            </div>
            <div className="flex w-full py-2 items-center  justify-between">
              <h1>Devenahalli Health Services</h1>
              <h1>3.1 km</h1>
            </div>
          </div>

          <div className="flex flex-col col-span-2 items-center md:justify-start justify-between bg-[#F8F7F5]">
          <a href="https://www.google.com/maps/place/Rak+groups/@13.2629215,77.7193857,14.73z/data=!4m9!1m2!2m1!1sRak+Groups+Office,+Above+raymonds+showroom,+Prashanth+nagar,+Devanahalli+-+562110!3m5!1s0x3bb1e334ff9d44b3:0x9a1988a5d56706a!8m2!3d13.2661958!4d77.7281971!16s%2Fg%2F11tfkjptj2?entry=ttu&g_ep=EgoyMDI0MTAwMi4xIKXMDSoASAFQAw%3D%3D" className=" "><img alt="amap" src={n1} width="100%" ></img></a>
          </div>

          <div className="flex flex-col md:space-x-6 col-span-2 md:col-span-1 p-2 md:p-10 items-center md:justify-start justify-between bg-[#F8F7F5] text-xs sm:text-sm md:text-lg">
            <div className="flex w-full text-sm sm:text-lg md:text-xl  font-semibold py-2 items-center  justify-start">
              <h1>Restaurants</h1>
            </div>
            <div className="flex w-full py-2 items-center  justify-between">
              <h1>The Arab Restaurants</h1>
              <h1>1 km</h1>
            </div>
            <div className="flex w-full py-2 items-center  justify-between">
              <h1>Nandi Upachar Restaurant</h1>
              <h1>1.3 km</h1>
            </div>
            <div className="flex w-full py-2 items-center  justify-between">
              <h1>Hotel Andra Deluxe</h1>
              <h1>3 km</h1>
            </div>
            <div className="flex w-full py-2 items-center  justify-between">
              <h1>The Green House Restaurant</h1>
              <h1>3.9 km</h1>
            </div>
            <div className="flex w-full py-2 items-center  justify-between">
              <h1>JWMarriott Resort Hotel Spa</h1>
              <h1>5.8 km</h1>
            </div>
          </div>

          <div className="flex flex-col md:space-x-6 col-span-2 md:col-span-1 p-2 md:p-10 items-center md:justify-start justify-between bg-[#F8F7F5] text-xs sm:text-sm md:text-lg">
            <div className="flex w-full text-sm sm:text-lg md:text-xl  font-semibold py-2 items-center  justify-start">
              <h1>Schools</h1>
            </div>
            <div className="flex w-full py-2 items-center  justify-between">
              <h1>Gnanasagara Public School</h1>
              <h1>3.6 km</h1>
            </div>
            <div className="flex w-full py-2 items-center  justify-between">
              <h1>Akash Medical College</h1>
              <h1>4.7 km</h1>
            </div>
            <div className="flex w-full py-2 items-center  justify-between">
              <h1>Chanakya University</h1>
              <h1>5.4 km</h1>
            </div>
            <div className="flex w-full py-2 items-center  justify-between">
              <h1>MVM central School</h1>
              <h1>10 km</h1>
            </div>
            <div className="flex w-full py-2 items-center  justify-between">
              <h1>Nandini Vidyanikethana</h1>
              <h1>10 km</h1>
            </div>
          </div>

          <div className="flex flex-col md:space-x-6 col-span-2 md:col-span-1 p-2 md:p-10 items-center md:justify-start justify-between bg-[#F8F7F5] text-xs sm:text-sm md:text-lg">
            <div className="flex w-full text-sm sm:text-lg md:text-xl  font-semibold py-2 items-center  justify-start">
              <h1>Others</h1>
            </div>
            <div className="flex w-full py-2 items-center  justify-between">
              <h1>Clarks Exotica Conventional Resort & Spa</h1>
            </div>
            <div className="flex w-full py-2 items-center  justify-between">
              <h1>Taj Bangalore</h1>
            </div>
            <div className="flex w-full py-2 items-center  justify-between">
              <h1>Angsana Oasis Spa & Resort</h1>
            </div>
            <div className="flex w-full py-2 items-center  justify-between">
              <h1>KIADB Aerospace SEZ</h1>
            </div>
            <div className="flex w-full py-2 items-center  justify-between">
              <h1>Prestige Tech Cloud Park</h1>
            </div>
          </div>
        </div>
      </div>

      <div className="px-6 md:px-20 md:pt-10">
        <h1 className=" font-semibold text-xl sm:text-2xl md:text-3xl mb-2">
          Property Gallery
        </h1>
        <div className="w-full relative justify-center flex flex-col md:mb-10 mb-4">
          <hr className="bg-gray-400 h-0.5 w-full absolute" />
          <hr className="bg-[#CCAC00] h-1 w-1/4 absolute" />
        </div>
      </div>
      <div className="w-full mt-10 px-4  animate-fade-in delay-300 md:pb-10">
        <Carousel
          showThumbs={false}
          infiniteLoop={true}
          autoPlay={true}
          showStatus={false}
          centerMode={!isMobile} // Disable centerMode on mobile
          centerSlidePercentage={60} // Show 60% width for the center image
          dynamicHeight={false}
          onChange={handleSlideChange}
        >
          {projects.map((project, index) => (
            <Link to={project.link} key={project.id}>
              <div
                className={`carousel-item ${
                  index === activeIndex ? "active-slide" : ""
                } animate-zoom-in delay-${index * 100}`}
              >
                <img
                  src={project.imageUrl}
                  alt={project.title}
                  className="carousel-image"
                />
              </div>
            </Link>
          ))}
        </Carousel>
      </div>

      {/* Footer Section */}
      <Footer />
    </div>
  );
};

export default VillaDetailsPage;
