// src/context/AuthContext.js
import React, { createContext, useContext, useState, useEffect } from 'react';
import axios from 'axios';
import { getAuthToken, setAuthToken } from '../utils/auth';
//import { useNavigate } from 'react-router-dom';


const AuthContext = createContext();

export const AuthProvider = ({ children }) => {
  const [isAuthenticated, setIsAuthenticated] = useState(false);
  const [userRole, setUserRole] = useState(null);
  //const navigate = useNavigate();
  const [user, setUser] = useState(null);
  useEffect(() => {
    const token = getAuthToken();
    const role = localStorage.getItem('role');
    if (token) {
      //axios.defaults.headers.common['Authorization'] = `Bearer ${token}`;
      setAuthToken(token);
      setIsAuthenticated(true);
      setUserRole(role);
    }
  }, []);

  const login = async (email, password) => {
    try {
      const res = await axios.post(`${process.env.REACT_APP_API_URL}/api/users/login`, { email, password });
      const { token, role, user } = res.data;
      setAuthToken(token);
      //localStorage.setItem('token', token);
      localStorage.setItem('role', role);
      setIsAuthenticated(true);
      setUserRole(role);
      setUser(user);

      return role;

    //  if (res.data.role === 'user') {
    //    navigate('/user-dashboard');
    //  } else if (res.data.role === 'agent') {
    //    navigate('/agent-dashboard');
    //  } else if (res.data.role === 'owner') {
    //    navigate('/owner-dashboard');
    //  }

    } catch (error) {
      console.error('Login failed', error);
      throw error;
    }
  };

  const logout = () => {
    setAuthToken(null);
    setIsAuthenticated(false);
    setUserRole(null);
    setUser(null);
    localStorage.removeItem('token');
    localStorage.removeItem('role');
  };

  return (
    <AuthContext.Provider value={{ isAuthenticated, userRole, login, logout, user}}>
      {children}
    </AuthContext.Provider>
  );
};

export const useAuth = () => useContext(AuthContext);
