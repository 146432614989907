import React from "react";
import NavBarHome from "../components/NavBarHome";
import Footer from "../components/Footer";
import wp from "../assets/wp.png";
import { Link } from "react-router-dom";
import hero from "../assets/hero-image.png";

const HomePage = () => {
  

  return (
    <div className=" h-screen md:h-full">
      <div
        className="relative w-full h-full bg-cover bg-center"
        style={{ backgroundImage: `url(${hero})` }}
      >
        <img
          src={hero}
          useMap="#image-map"
          alt="Hero Background"
          className="absolute top-0 left-0 opacity-0 pointer-events-none"
          style={{ width: "100%", height: "100%" }}
        />

        <NavBarHome />
        {/* Apply flex and centering only on mobile view */}
        <div className="flex flex-col justify-center  h-screen px-6 md:flex-col md:px-32 md:py-32 ">
          {/* Heading with Fade-in and Slide-up Effect */}
          <h1 className="text-white font-semibold text-xl sm:text-2xl md:text-3xl lg:text4xl xl:text-5xl 2xl:text-6xl w-full md:w-6/12 text-left animate-slide-up">
            <span className="text-[#CCAC00]">"</span>More than just homes, we
            offer dreams to own. Discover the space where your{" "}
            <span className="text-[#CCAC00]">future begins." </span>
          </h1>

          {/* Button with Zoom-in Effect */}
          <Link to="/plotdetailspage">
            <button className="bg-[#CCAC00] text-gray-900 px-4 py-2 sm:px-6 sm:py-3 rounded-full mt-6 sm:mt-8 transition duration-300 ease-in-out transform hover:bg-yellow-600 hover:scale-110 animate-zoom-in delay-400">
              Book Now
            </button>
          </Link>

          {/* WhatsApp Chat Button */}
          <div className="fixed bottom-5 right-5 flex items-center group">
            <div
              id="whatsapp"
              className="h-7 px-2 sm:px-4 text-black rounded-full mr-2 sm:mr-3 bg-white opacity-0 group-hover:opacity-100 transition-opacity duration-300"
            >
              Hello There, chat with us!
            </div>

            <Link to="https://wa.me/9059005900">
              <img
                src={wp}
                alt="whatsapp logo"
                className="h-12 sm:h-14 md:h-16 bg-center align-middle transition duration-300 ease-in-out transform hover:scale-110"
              />
            </Link>
          </div>
        </div>
      </div>
      <Footer />
    </div>
  );
};

export default HomePage;
