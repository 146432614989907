import React from "react";
import { Link } from "react-router-dom";
import logo from "../assets/RAK_logo_bk.png";

const Footer = () => {
  return (
    <footer className="bg-slate-100 text-slate-500 py-8">
      <div className="px-4 sm:px-8 md:px-20 lg:px-20 grid grid-cols-1 sm:grid-cols-2 md:grid-cols-4 gap-8 md:gap-10 lg:gap-12 justify-between">
        <div>
          <Link to="/home">
            <img src={logo} alt="RAK LOGO" className="bg-center h-16 sm:h-20" />
          </Link>
          <p className="text-xs sm:text-sm   mt-5 font-light">
          Expertly crafted with top-tier amenities, these homes redefine modern community living. Step into the vibrant lifestyle of Rak Groups and start your new chapter today.
          </p>
        </div>

        <div>
          
          <ul className="flex flex-col gap-3 md:gap-6 text-sm sm:text-sm ">
            <li>
              <Link to="/home" className="hover:text-yellow-300">
                Home
              </Link>
            </li>
            <li>
              <Link to="/about" className="hover:text-yellow-300">
                About
              </Link>
            </li>
            <li>
              <Link to="/projects" className="hover:text-yellow-300">
                Projects
              </Link>
            </li>
            <li>
              <Link to="/team" className="hover:text-yellow-300">
                Our Team
              </Link>
            </li>
            <li>
              <Link to="/enquire" className="hover:text-yellow-300">
                Enquire Now
              </Link>
            </li>
            <li>
              <Link to="/tandc" className="hover:text-yellow-300">
                Terms and Conditions
              </Link>
            </li>
          </ul>
        
        </div>

        <div>
          <h2 className="text-sm sm:text-lg md:text-xl font-bold mb-3 md:mb-5">
            Contact Us
          </h2>
          <p className="text-xs sm:text-sm font-light">Rak Groups Office, Prashanth nagar, Devanahalli - 562110</p>
          <p className="text-xs sm:text-sm font-light flex items-center mt-1 ">
            <svg
              xmlns="http://www.w3.org/2000/svg"
              fill="none"
              viewBox="0 0 24 24"
              strokeWidth={1.5}
              stroke="currentColor"
              className="h-5 w-5 mr-2"
            >
              <path
                strokeLinecap="round"
                strokeLinejoin="round"
                d="M2.25 6.75c0 8.284 6.716 15 15 15h2.25a2.25 2.25 0 0 0 2.25-2.25v-1.372c0-.516-.351-.966-.852-1.091l-4.423-1.106c-.44-.11-.902.055-1.173.417l-.97 1.293c-.282.376-.769.542-1.21.38a12.035 12.035 0 0 1-7.143-7.143c-.162-.441.004-.928.38-1.21l1.293-.97c.363-.271.527-.734.417-1.173L6.963 3.102a1.125 1.125 0 0 0-1.091-.852H4.5A2.25 2.25 0 0 0 2.25 4.5v2.25Z"
              />
            </svg>
            9059005900
          </p>
          <p className="text-xs sm:text-sm  font-light flex items-center mt-1">
            <svg
              xmlns="http://www.w3.org/2000/svg"
              fill="none"
              viewBox="0 0 24 24"
              strokeWidth={1.5}
              stroke="currentColor"
              className="h-5 w-5 mr-2"
            >
              <path
                strokeLinecap="round"
                strokeLinejoin="round"
                d="M21.75 6.75v10.5a2.25 2.25 0 0 1-2.25 2.25h-15a2.25 2.25 0 0 1-2.25-2.25V6.75m19.5 0A2.25 2.25 0 0 0 19.5 4.5h-15a2.25 2.25 0 0 0-2.25 2.25m19.5 0v.243a2.25 2.25 0 0 1-1.07 1.916l-7.5 4.615a2.25 2.25 0 0 1-2.36 0L3.32 8.91a2.25 2.25 0 0 1-1.07-1.916V6.75"
              />
            </svg>
            info@email.rakgroups.in
          </p>
        </div>
        <div>
          <h2 className="text:sm sm:text-lg md:text-xl font-bold mb-3 md:mb-5">
            Follow Us
          </h2>
          <div className="flex space-x-3 mt-3">
            <a
              href="/error"
              className="border-[#CCAC00] border rounded-xl h-8 w-8 flex items-center justify-center bg-[#CCAC00]"
            >
              <svg
                className="h-5 w-5 text-white"
                viewBox="0 0 24 24"
                fill="none"
                stroke="currentColor"
                strokeWidth="2"
                strokeLinecap="round"
                strokeLinejoin="round"
              >
                <rect x="2" y="2" width="20" height="20" rx="5" ry="5" />
                <path d="M16 11.37A4 4 0 1 1 12.63 8 4 4 0 0 1 16 11.37z" />
                <line x1="17.5" y1="6.5" x2="17.51" y2="6.5" />
              </svg>
            </a>

            <a
              href="/error"
              className="border-[#CCAC00] bg-[#CCAC00] border rounded-xl h-8 w-8 flex items-center justify-center"
            >
              <svg
                className="h-5 w-5 text-white"
                viewBox="0 0 24 24"
                fill="none"
                stroke="currentColor"
                strokeWidth="2"
                strokeLinecap="round"
                strokeLinejoin="round"
              >
                <path d="M22.54 6.42a2.78 2.78 0 0 0-1.94-2C18.88 4 12 4 12 4s-6.88 0-8.6.46a2.78 2.78 0 0 0-1.94 2A29 29 0 0 0 1 11.75a29 29 0 0 0 .46 5.33A2.78 2.78 0 0 0 3.4 19c1.72.46 8.6.46 8.6.46s6.88 0 8.6-.46a2.78 2.78 0 0 0 1.94-2 29 29 0 0 0 .46-5.25 29 29 0 0 0-.46-5.33z" />
                <polygon points="9.75 15.02 15.5 11.75 9.75 8.48 9.75 15.02" />
              </svg>
            </a>

            <a
              href="/error"
              className="border-[#CCAC00] bg-[#CCAC00] border rounded-xl h-8 w-8 flex items-center justify-center"
            >
              <svg
                className="h-5 w-5 text-white"
                width="24"
                height="24"
                viewBox="0 0 24 24"
                fill="none"
                stroke="currentColor"
                strokeWidth="2"
                strokeLinecap="round"
                strokeLinejoin="round"
              >
                <path stroke="none" d="M0 0h24v24H0z" fill="none" />
                <path d="M4 4l11.733 16h4.267l-11.733 -16z" />
                <path d="M4 20l6.768 -6.768m2.46 -2.46l6.772 -6.772" />
              </svg>
            </a>
          </div>
        </div>
      </div>
      <div className="text-xs sm:text-sm md:text-base text-center mt-10 md:mt-10">
        <hr />
        <p className="mt-5 md:mt-10">
          &copy; 2024 real estates | All Rights Reserved
        </p>
      </div>
    </footer>
  );
};

export default Footer;
